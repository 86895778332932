import * as constants from "../../constants/index";
import { GlobalSettingsState } from "./types";

const getInitialState = () => {
  const initialState: GlobalSettingsState = {
    minIssuedByDate: null,
    maxIssuedByDate: null,
    tenantName: null,
    maxAHVPensionPerMonth: 0,
    isBridingPossible: false,
    suggestMaxBridgingPensionValues: false,
    suggestAHVPension: false,
    hasMultiplePlans: false,
    hasPayrollClasses: false,
    defaultRetirmentAgeMale: 0,
    defaultRetirmentAgeFemale: 0,
    isPlanChoicePossible: false,
    customDesign: null,
    insurancePlanList: null,
    locizeSettings: null,
    disableChartDefaultPension: false,
  };

  return initialState;
};

const globalSettingsState = (
  state: GlobalSettingsState = getInitialState(),
  action: any
) => {
  switch (action.type) {
    case constants.FETCHED_SETTINGS: {
      const data = action.payload?.data?.data;
      return {
        ...state,
        globalSettingsFetched: true,
        isPlanChoicePossible: data?.isPlanChoicePossible,
        hasMultiplePlans: data?.hasMultiplePlans,
        tenantName: action?.payload?.tenantName,
        hasPayrollClasses: data?.hasPayrollClasses,
        isBridingPossible: data?.isBridingPossible,
        defaultRetirmentAgeFemale: data?.defaultRetirmentAgeFemale,
        suggestMaxBridgingPensionValues: data?.suggestMaxBridgingPensionValues,
        defaultRetirmentAgeMale: data?.defaultRetirmentAgeMale,
        maxAHVPensionPerMonth: data?.maxAHVPensionPerMonth || 0,
        suggestAHVPension: data?.suggestAHVPension,
        maxIssuedByDate: data?.maxIssuedByDate,
        minIssuedByDate: data?.minIssuedByDate,
        insurancePlanList: data?.insurancePlanList || [],
        examplePicturesSet: data?.customDesign?.examplePicturesSet,
        customDesign: data?.customDesign || null,
        locizeSettings: data?.locizeSettings || null,
        disableChartDefaultPension: data?.disableChartDefaultPension || false,
      };
    }
  }

  return state;
};

export default globalSettingsState;
