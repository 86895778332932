import { IStandalonePersonInputProps } from "pensiondynamics.graph.engine/src/GraphBase/Person/IStandalonePersonInputProps";
import * as constants from "../../constants";
import { parseStringDateToDate } from "../../utils/react";

export interface StandaloneStartupState {
  isStandaloneUntouched: boolean;
  isStandaloneCompleted: boolean;
  person: IStandalonePersonInputProps;
}

const getInitialState = () => {
  const tenantName = sessionStorage.getItem("tenantName") || "BVG";

  const standaloneData = sessionStorage.getItem("standaloneData");

  const initialState: StandaloneStartupState = JSON.parse(standaloneData) ?? {
    isStandaloneUntouched: true,
    isStandaloneCompleted: false,
    person: {
      activityRate: null,
      balanceLastYear: null,
      birthDate: null,
      certificateDate: null,
      grossSalary: null,
      sex: 0,
      tenantCode: tenantName,
      planCode: null,
    },
  };

  return initialState;
};

const standaloneStartupState = (
  state: StandaloneStartupState = getInitialState(),
  action: any
) => {
  switch (action.type) {
    case constants.SET_STANDALONE_TENANT_CODE: {
      sessionStorage.setItem("tenantName", action.payload);

      return {
        ...state,
        person: {
          ...state.person,
          tenantCode: action.payload,
        },
      };
    }
    case constants.SET_STANDALONE_BIRTHDATE: {
      return {
        ...state,
        isStandaloneUntouched: false,
        person: {
          ...state.person,
          birthDate: parseStringDateToDate(action.payload),
        },
      };
    }
    case constants.SET_STANDALONE_COMPLETED: {
      const newState = {
        ...state,
        isStandaloneCompleted: true,
        person: {
          ...state.person,
        },
      };
      sessionStorage.setItem("standaloneData", JSON.stringify(newState));

      return newState;
    }
    case constants.SET_STANDALONE_SEX: {
      return {
        ...state,
        person: {
          ...state.person,
          sex: action.payload,
        },
      };
    }
    case constants.SET_STANDALONE_PLAN_CODE: {
      return {
        ...state,
        person: {
          ...state.person,
          planCode: action.payload,
        },
      };
    }
    case constants.SET_STANDALONE_CERTIFICATE_DATE: {
      return {
        ...state,
        person: {
          ...state.person,
          certificateDate: parseStringDateToDate(action.payload),
        },
      };
    }
    case constants.SET_STANDALONE_BALANCE_LAST_YEAR: {
      return {
        ...state,
        person: {
          ...state.person,
          balanceLastYear: Number(action.payload),
        },
      };
    }
    case constants.SET_STANDALONE_GROSS_SALARY: {
      return {
        ...state,
        person: {
          ...state.person,
          grossSalary: Number(action.payload),
        },
      };
    }
    case constants.SET_STANDALONE_ACTIVITY_RATE: {
      return {
        ...state,
        person: {
          ...state.person,
          activityRate: Number(action.payload),
        },
      };
    }
    case constants.RESET_STANDALONE: {
      return getInitialState();
    }
  }

  return state;
};

export default standaloneStartupState;
