// import './config';
export const TENANT_SET = "TENANT_SET";
export const LANG_SET = "LANG_SET";
export const LOADING = "LOADING";
export const I18N_LOADING = "I18N_LOADING";
export const SIMULATE_LOADING = "SIMULATE_LOADING";
export const CLEAR_DATA = "CLEAR_DATA";
export const CLEAR_CONTROLS = "CLEAR_CONTROLS";
export const SET_MONTHLY_OR_YEARLY = "SET_MONTHLY_OR_YEARLY";
export const SET_STANDALONE_COMPLETED = "SET_STANDALONE_COMPLETED";

export const ADD_NEW_INSURANCE = "ADD_NEW_INSURANCE";
export const ADD_NEW_INSURANCE_ERROR = "ADD_NEW_INSURANCE_ERROR";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_REFRESH_TOKEN = "LOGIN_REFRESH_TOKEN";
export const LOG_OUT = "LOG_OUT";
export const UPDATE_LOCALES = "UPDATE_LOCALES";

export const FETCH_ERROR = "FETCH_ERROR";
export const POST_ERROR = "POST_ERROR";
export const PATCH_ERROR = "PATCH_ERROR";
export const DELETE_ERROR = "DELETE_ERROR";

export const POST_SUCCESS = "POST_SUCCESS";
export const PATCH_SUCCESS = "PATCH_SUCCESS";
export const DELETE_SUCCESS = "DELETE_SUCCESS";

export const USERDATA_DETAILLIERUNG = "USERDATA_DETAILLIERUNG";
export const USERDATA_DATENQUELLE = "USERDATA_DATENQUELLE";
export const USERDATA_WOHNORT = "USERDATA_WOHNORT";
export const USERDATA_VPKONTO = "USERDATA_VPKONTO";
export const USERDATA_VPKONTO_AMOUNT = "USERDATA_VPKONTO_AMOUNT";
export const USERDATA_CAPITAL_PURCHASE = "USERDATA_CAPITAL_PURCHASE";
export const USERDATA_CAPITAL_WITHDRAWAL = "USERDATA_CAPITAL_WITHDRAWAL";
export const USERDATA_BRIDGINGPENSION = "USERDATA_BRIDGINGPENSION";
export const USERDATA_CAPITAL_PURCHASE_REMOVE =
  "USERDATA_CAPITAL_PURCHASE_REMOVE";
export const USERDATA_LOHNKLASSE = "USERDATA_LOHNKLASSE";
export const USERDATA_TEILPENSION_REMOVE = "USERDATA_TEILPENSION_REMOVE";
export const FETCHED_CALCULATIONS = "FETCHED_CALCULATIONS";
export const FETCHED_CALCULATIONS_FAILED = "FETCHED_CALCULATIONS_FAILED";
export const FETCHED_MAX_PURCHASE = "FETCHED_MAX_PURCHASE";
export const FETCHED_SETTINGS = "FETCHED_SETTINGS";
export const SET_STEPS_OPENED = "SET_STEPS_OPENED";
export const FORCE_RECALCULATE = "FORCE_RECALCULATE";
export const FETCHED_INSURANT_DATA = "FETCHED_INSURANT_DATA";
export const FORCE_RENDER_AFTER_INSURANT_LOAD =
  "FORCE_RENDER_AFTER_INSURANT_LOAD";
export const UPDATE_MAX_VALUES = "UPDATE_MAX_VALUES";

export const USERDATA_HOMEOWNERSHIP = "USERDATA_HOMEOWNERSHIP";
export const CLEAR_USERDATA_HOMEOWNERSHIP = "CLEAR_USERDATA_HOMEOWNERSHIP";

export const USERDATA_DIVORCEPOSTINGS = "USERDATA_DIVORCEPOSTINGS";
export const USERDATA_DIVORCETOTALVALUE = "USERDATA_DIVORCETOTALVALUE";

export const FETCHED_GLOBAL_SETTINGS = "FETCHED_GLOBAL_SETTINGS";
export const SINCRONIZE_MAXPURCHASES = "SINCRONIZE_MAXPURCHASES";
export const REMOVE_INSURANT_PORTAL_DATA = "REMOVE_INSURANT_PORTAL_DATA";

// STANDALONE STARTUP
export const SET_STANDALONE_TENANT_CODE = "SET_STANDALONE_TENANT_CODE";
export const SET_STANDALONE_BIRTHDATE = "SET_STANDALONE_BIRTHDATE";
export const SET_STANDALONE_SEX = "SET_STANDALONE_SEX";
export const SET_STANDALONE_PLAN_CODE = "SET_STANDALONE_PLAN_CODE";
export const SET_STANDALONE_CERTIFICATE_DATE =
  "SET_STANDALONE_CERTIFICATE_DATE";
export const SET_STANDALONE_BALANCE_LAST_YEAR =
  "SET_STANDALONE_BALANCE_LAST_YEAR";
export const SET_STANDALONE_GROSS_SALARY = "SET_STANDALONE_GROSS_SALARY";
export const SET_STANDALONE_ACTIVITY_RATE = "SET_STANDALONE_ACTIVITY_RATE";
export const RESET_STANDALONE = "RESET_STANDALONE";

// TEIL PENSION
export const SET_TEILPENSION_DATE = "SET_TEILPENSION_DATE";
export const SET_TEILPENSION_GROSS_SALARY = "SET_TEILPENSION_GROSS_SALARY";
export const SET_TEILPENSION_RETIREMENT_PERCENTAGE =
  "SET_TEILPENSION_RETIREMENT_PERCENTAGE";
export const SET_TEILPENSION_ACTIVITY_RATE = "SET_TEILPENSION_ACTIVITY_RATE";
export const SET_TEILPENSION_FINISHED = "SET_TEILPENSION_FINISHED";
export const RESET_TEILPENSION = "RESET_TEILPENSION";

// GRAPH
export const INITIATE_GRAPH_DATA = "INITIATE_GRAPH_DATA";
export const RESET_GRAPH_DATE = "RESET_GRAPH_DATE";
export const CALCULATE_GRAPH_DATA = "CALCULATE_GRAPH_DATA";
export const SET_AHV_PENSION_FROM_STANDALONE =
  "SET_AHV_PENSION_FROM_STANDALONE";
export const SET_PAYROLL_CLASS_FROM_STANDALONE =
  "SET_PAYROLL_CLASS_FROM_STANDALONE";
//
export const SET_BEFORE_PENSION_CARDS_LIST = "SET_BEFORE_PENSION_CARDS_LIST";
export const SET_AFTER_PENSION_CARDS_LIST = "SET_AFTER_PENSION_CARDS_LIST";

export const SLIDER_VALUE_PURCHASE = "SLIDER_VALUE_PURCHASE";
export const USERDATA_ADDITIONAL_SAVING_PLANS =
  "USERDATA_ADDITIONAL_SAVING_PLANS";
export const USERDATA_ADDITIONAL_SAVING_PLAN_SELECTED =
  "USERDATA_ADDITIONAL_SAVING_PLAN_SELECTED";

export const INDEX_TO_REMOVE = "INDEX_TO_REMOVE";
export const SET_LANGUAGES_LIST = "SET_LANGUAGES_LIST";

// Animations

export const animationConfig = () => {
  return {
    startSec: 0,
    easing: "Expo.easeInOut",
    easingStart: window.innerWidth < 897 ? "Circ.easeIn" : "Expo.easeInOut",
    scale: 1,
    stagger: {
      each: 0.2,
      // ease: "Power4.easeInOut"
    },
  };
};

export const months = (lang) => {
  switch (lang) {
    case "en-US":
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    case "it-IT":
      return [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
    case "fr-FR":
      return [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
    case "pt-BR":
      return [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
    default:
      return [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ];
  }
};

(window as any).getTranslations = function () {
  let translations = {};
  document.querySelectorAll("[data-translate]").forEach((el) => {
    let key = (el as any).dataset.translate;

    if (key.split(".")[1]) {
      translations[key.split(".")[0]] = translations[key.split(".")[0]] || {};
      translations[key.split(".")[0]][key.split(".")[1]] = (el as any)
        .textContent.length
        ? (el as any).textContent
        : (el as any).getAttribute("placeholder");
    } else {
      translations[key] = (el as any).textContent.length
        ? (el as any).textContent
        : (el as any).getAttribute("placeholder");
    }
  });
  return translations;
};
